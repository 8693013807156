<template>
  <DataTable
    :headers="headers"
    :objects="value"
    class="mx-4"
    :pagination.sync="pagination"
    hideRowsPerPage
    hideToolBar
  >
    <template v-slot:cell="{ header, objects }">
      <component
        :is="cellFactory(header, objects)"
        :header="header"
        :item="objects.item"
        @action="handleAction"
      ></component>
    </template>
  </DataTable>
</template>

<script>
export default {
  props: {
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Miniaturka",
          sortable: false,
          align: "left",
          value: "file",
        },
        {
          text: "Data dodania",
          sortable: true,
          align: "left",
          value: "created",
        },
        {
          text: "Dodane przez",
          sortable: true,
          align: "left",
          value: "added_by",
        },
        {
          text: "Nazwa pliku",
          sortable: true,
          align: "left",
          value: "title",
        },
        {
          text: "Rozmiar",
          sortable: true,
          align: "left",
          value: "human_size",
        },
        {
          text: "",
          align: "right",
          sortable: false,
        },
      ],
      pagination: {
        rowsPerPage: -1,
        page: 1,
        sortBy: "created",
        descending: true,
      },
    };
  },
  methods: {
    cellFactory(header, objects) {
      switch (header.text) {
        case "Miniaturka":
          return "ImageCell";
        case "":
          return "FileToolsCell";
        default:
          return "DefaultCell";
      }
    },
    handleAction(value) {
      switch (value.action) {
        case "pdf":
          return this.onOpenClickedPdf(value.item);
        case "edit":
          return this.onEditClicked(value.item);
        case "open":
          return this.onOpenClicked(value.item);
        case "delete":
          return this.onDeleteClicked(value.item);
        default:
          return this.onDownloadClicked(value.item);
      }
    },
    onEditClicked(file) {
      this.$emit("edit", file);
    },
    onDeleteClicked(file) {
      this.$emit("delete", file);
    },
    onDownloadClicked(file) {
      this.$emit("download", file);
    },
    onOpenClicked(file) {
      this.$emit("open", file);
    },
    onOpenClickedPdf(file) {
      this.$emit("openPdf", file);
    },
  },
  components: {
    DataTable: () => import("@/components/tables/DataTable"),
    DefaultCell: () => import("@/components/tables/DefaultCell"),
    ImageCell: () => import("@/components/tables/files/ImageCell"),
    FileToolsCell: () => import("@/components/tables/files/FileToolsCell"),
  },
};
</script>
